import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import { FetchCourseDetails, FetchBatchData, AddNewBatch } from "../../api/aws-api";
import { ConstructorEventGet, ConstructorEventPost } from "../../Event/event";
import { ClassicSpinner } from "react-spinners-kit";
import { useLocation } from "react-router-dom"
import { gapi } from 'gapi-script';
import Swal from 'sweetalert2'
import { Table, Input } from "antd";
import "../../assets/css/antdstyle.css";
import { FetchStudentDetails } from "../../api/aws-api";
import { notifySuccess, notifyError } from "../../Common/CommonFunction";



const AddBatch = (props) => {
  const location = useLocation();
  const [courseOptions, setCourseOptions] = useState([]);
  const [prevCalculateDateTime, setPrevCalculateDateTime] = useState([]);
  const [startdate, setStartDate] = useState();
  const [enddate, setEndDate] = useState();
  const [showeventmenu, setShoweventmenu] = useState(false);
  const [loading, setLoading] = useState(false)
  const [course, setCourse] = useState();
  const [calenderEventDates, setCalenderEventDates] = useState([])
  const [showAddStudentButton, setShowAddStudentButton] = useState(false)
  const [recurrent, setRecurrent] = useState("Mon--Fri");
  const [coursedata, setCoursedata] = useState([]);
  const [starttime, setStartTime] = useState("12:30");
  const [endtime, setEndTime] = useState("13:30");
  const [prevEvents, setPrevEvents] = useState([])
  const [calenderEvents, setCalenderEvents] = useState([])
  const [batchData, setBatchData] = useState([])
  const navigate = useNavigate()
  const [userMail, setUserMail] = useState("")
  const [CustomBatchShedule, setCustomBatchShedule] = useState(true)//Custom
  const [Customdate, setCustomDate] = useState();
  const [Customstarttime, setCustomStartTime] = useState("12:30");
  const [Customendtime, setCustomEndTime] = useState("13:30");
  const [Customdatelist, setCustomDateList] = useState([]);
  const [meetLink, setMeetLink] = useState("")
  const [signedIn, setSignedIn] = useState(false);
  const [isAddStudentClicked, setIsAddStudentClicked] = useState(false)
  const [studentEmail, setStudentEmail] = useState([]);


  const [submitloading, setSubmitloading] = useState(false)
  const [addedStudents, setAddedStudents] = useState([])
  const [batchDetails, setBatchDetails] = useState([])
  const [enrolled, setEnrolled] = useState([])
  const [studentDetails, setStudentDetails] = useState([])
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [forbatcTime, setForbatchTime] = useState([]);
  const [studentsAlreadyInBatch, setStudentsAlreadyInBatch] = useState()


  const CLIENT_ID = '347764156937-klkgu25nrg2l7pq8iticmrvha29f3vr7.apps.googleusercontent.com';
  const API_KEY = 'AIzaSyCio8YcxXtf28_wN_ZWIHIjrlmzjGbXTJg';
  const SCOPES = 'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/admin.reports.audit.readonly';



  useEffect(() => {
    const { batchdata } = location.state ? location.state : []
    setBatchData(location.state)
    const filteredBatch = batchdata.filter(obj => obj.batchProgress === "Scheduled" || obj.batchProgress === "InProgress")
    AcumulatePreviousClassesToCalender(filteredBatch)
    const storedData = sessionStorage.getItem('CourseKey');
    const mentorName = sessionStorage.getItem("SelectedMentor")
    const mentorDetails = JSON.parse(mentorName)
    console.log("mentorDetails", mentorDetails);
    setUserMail(mentorDetails.user_email);
    setCourseOptions(mentorDetails.course)
    console.log(studentEmail, "studentemails");


    function start() {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        scope: SCOPES,
      }).then(() => {
        gapi.client.load('calendar', 'v3'); // Load the Calendar API library
        const authInstance = gapi.auth2.getAuthInstance();
        authInstance.signIn();
        setSignedIn(authInstance.isSignedIn.get());
        authInstance.isSignedIn.listen(setSignedIn);
      });
    }

    // console.log("the stored data is", storedData)
    // if (storedData !== "undefined" && storedData !== "null" && storedData !== undefined && storedData !== null) { setCourseOptions(JSON.parse(storedData)) }
    gapi.load('client:auth2', start);
    fetchdata()
    getBatchDatas()

  }, [])

  useEffect(() => {
    console.log("mentormail", userMail);
    console.log(studentEmail, "studentemails");
  }, [userMail, studentEmail]);




  const getBatchDatas = async () => {
    const responsedata = await ConstructorEventGet(FetchBatchData, { "mentorId": "" })
    console.log("the response data is ", responsedata
    )


    const removeCancelledBatches = responsedata.filter((ele) => {
      return ele.batchProgress != "Cancelled"
    })


    const removeCompletedBatches = removeCancelledBatches.filter((ele) => {
      return ele.batchProgress != "Completed"
    })

    console.log("the batches are", removeCompletedBatches)

    const finalData = removeCompletedBatches.map((ele) => {
      return ele.StudentsEndrolled.map((ele) => {
        return ele.key
      })
    })

    console.log("the final data is", finalData)

    const studentsId = [...new Set(finalData.flat())]
    console.log("the students enrolled are", studentsId)

    setStudentsAlreadyInBatch(studentsId)
    console.log("the students enrolled are", studentsId)

  }

  const handleDateSelect = (selectInfo) => {
    console.log("the showstudent button is", showAddStudentButton)
    setStartDate(dateChanger(selectInfo.start))
    setEndDate(recurrent === "Mon-Wed-Fri" ? addWorkingDaysMonWedFri(dateChanger(selectInfo.start), coursedata.total_class) : addWorkingDaysTueThursSat(dateChanger(selectInfo.start), coursedata.total_class))
  };

  const dateChanger = (today) => {
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Add leading zero if necessary
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
  }

  const dayOfWeek = (dateStr) => {
    const date = new Date(dateStr);
    const dayOfWeekIndex = date.getDay();
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayName = dayNames[dayOfWeekIndex];
    return dayName
  }

  const createGoogleMeet = () => {
    const attendees = studentEmail.map(email => ({ email }));

    // Optionally, include the mentor's or user's email
    attendees.push({ email: userMail });
    console.log(attendees, "attendees");
    const event = {
      summary: 'Google Meet Meeting',
      start: {
        dateTime: new Date(new Date().getTime() + 5 * 60 * 1000).toISOString(), // 5 minutes from now
        timeZone: 'America/Los_Angeles',
      },
      end: {
        dateTime: new Date(new Date().getTime() + 30 * 60 * 1000).toISOString(), // 30 minutes later
        timeZone: 'America/Los_Angeles',
      },
      attendees: attendees,

      conferenceData: {
        createRequest: {
          requestId: 'some-random-string',
          conferenceSolutionKey: {
            type: 'hangoutsMeet',
          },
        },
      },
    };

    gapi.client.calendar.events.insert({
      calendarId: 'primary',
      resource: event,
      conferenceDataVersion: 1,
    }).then(response => {
      console.log("the response", response.result.status)
      console.log('Meet link:', response.result.hangoutLink);
      if (response.result.status === "confirmed") {
        // NavigatetoStudentAddPage(response.result.hangoutLink)
        addBatchToTheDataBase(response.result.hangoutLink)
      }

    }).catch(error => {
      console.error('Error creating Google Meet:', error);
    });

  };


  const AddDurationToTime = (inputTime, inputDuration) => {

    console.log("classduration", inputDuration);
    // Convert input time and duration to hours and minutes
    var [hours, minutes] = inputTime.split(":").map(Number);
    var durationParts = inputDuration.match(/(\d+H)?(\d+M)?/);
    console.log("durationParts", durationParts)
    var durationHours = durationParts[1] ? parseInt(durationParts[1]) : 0;
    var durationMinutes = durationParts[2] ? parseInt(durationParts[2]) : 0;
    // Add duration to time
    var newHours = hours + durationHours;
    var newMinutes = minutes + durationMinutes;
    console.log(newHours, "newHours")
    console.log(newMinutes, "newMinutes")
    // Handle overflow of minutes
    if (newMinutes >= 60) {
      newHours += Math.floor(newMinutes / 60);
      newMinutes %= 60;
    }
    // Format output
    var outputTime = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
    console.log("outputTime", outputTime)
    return outputTime
  }
  const AcumulatePreviousClassesToCalender = (data) => {
    const totalPrevEvents = []
    const CalculateDateTime = []
    for (let batch = 0; batch < data.length; batch++) {
      if ('ClassSchedule' in data[batch]) {
        CalculateDateTime.push({
          startDate: data[batch].StartDate,
          endDate: data[batch].EndDate,
          startTime: data[batch].StartTime,
          endTime: data[batch].EndTime,
        })
        for (let sheduledclass = 0; sheduledclass < data[batch]["ClassSchedule"].length; sheduledclass++) {
          totalPrevEvents.push(
            {
              title: data[batch]["BatchName"] + "(" + (data[batch]["ClassSchedule"][sheduledclass]["scheduleNumber"]) + ")",
              start: data[batch]["ClassSchedule"][sheduledclass]["startDateTime"],
              end: data[batch]["ClassSchedule"][sheduledclass]["endDateTime"],
              scheduleNumber: data[batch]["ClassSchedule"][sheduledclass]["scheduleNumber"],
            }
          )
        }
      }
    }
    setPrevCalculateDateTime(CalculateDateTime)
    setPrevEvents(totalPrevEvents)
    setCalenderEvents(totalPrevEvents)
  }
  const IncludeInCustomDateList = () => {

    console.log("adding customdates pressed")
    var temp = {
      startDateTime: Customdate + "T" + Customstarttime + ":00",
      endDateTime: Customdate + "T" + Customendtime + ":00",
      status: "Scheduled",
      meet_link: meetLink
    }
    const check = doesCustomDateOverlapWithPreviosDate(Customdate, Customstarttime, Customendtime)
    if (!check) { setCustomDateList([...Customdatelist, temp]) }

  }
  const removeDateFromCustomDateList = (startDateTime) => {
    const updatedList = Customdatelist.filter((item) => item["startDateTime"] !== startDateTime);
    setCustomDateList(updatedList);
  };

  const doesCustomDateOverlapWithPreviosDate = (customdate, startTime, endTime) => {
    console.log("it comes inside the customoverlappreviousdate", customdate, startTime, endTime)

    const date = new Date(customdate);
    const timeStart = new Date(`1970-01-01T${startTime}`);
    const timeEnd = new Date(`1970-01-01T${endTime}`);
    let flag = false
    for (const data of prevCalculateDateTime) {
      const date2Start = new Date(data.startDate);
      const date2End = new Date(data.endDate);
      const time2Start = new Date(`1970-01-01T${data.startTime}`);
      const time2End = new Date(`1970-01-01T${data.endTime}`);
      time2End.setMinutes(time2End.getMinutes() + 15);
      console.log("sssss", time2End)
      if (date <= date2End && date >= date2Start) {
        if (timeStart < time2End && timeEnd > time2Start) {
          flag = true // Ranges overlap
          Swal.fire({
            icon: "warning",
            title: "Can't be scheduled",
            text: "You have already class in this timing",
            // footer: '<a href="#">Why do I have this issue?</a>'
          });
        }
      }
    }
    return flag
  }

  function doesRangeOverlapWithPreviousData(startdate, enddate, starttime, endtime) {


    console.log("dates are", startdate, enddate, starttime, endtime)
    console.log("prevDates are", prevCalculateDateTime)


    const newDate = prevCalculateDateTime.filter((ele) => {
      return ele.startDate === startdate
    })
    if (newDate.length > 0) {
      for (let i = 0; i < newDate.length; i++) {
        const inputTime = new Date(`1970-01-01T${starttime}:00Z`);
        const startingTime = new Date(`1970-01-01T${newDate[i].startTime}:00Z`);
        const endingTime = new Date(`1970-01-01T${newDate[i].endTime}:00Z`);

        endingTime.setMinutes(endingTime.getMinutes() + 15);
        console.log("input time ", inputTime)
        if (inputTime >= startingTime && inputTime < endingTime) {
          // setShowAddStudentButton(false)
          console.log("overlaps")

          Swal.fire({
            icon: "warning",
            title: "Can't be scheduled",
            text: "You have already class in this timing",
            // footer: '<a href="#">Why do I have this issue?</a>'
          });
        } else {
          // setShowAddStudentButton(true)
        }
      }


    } else {
      // setShowAddStudentButton(true)
    }

  }



  const fetchCourseDetails = async () => {

    console.log("the dee", addedStudents)
    setLoading(true)
    const coursedfulldata = courseOptions.find(obj => obj.course_name === course);
    const responsedata = await ConstructorEventGet(FetchCourseDetails, { course_id: coursedfulldata.course_id })
    console.log("rrrrrrrrrrr", responsedata)
    setCoursedata(responsedata)
    setStartDate(dateChanger(new Date()))
    setCustomDate(dateChanger(new Date()))
    setEndDate(addWorkingDaysMonWedFri(dateChanger(new Date()), responsedata.total_class))
    // setEndTime(AddDurationToTime("12:30", responsedata.class_duration))
    setShoweventmenu(true)
    setLoading(false)
  }

  function addWorkingDays(date, numDays) {
    const startDate = new Date(date);
    const endDate = new Date(startDate);
    let count = 1;
    let datelist = [dateChanger(startDate)]
    while (count < numDays) {
      endDate.setDate(endDate.getDate() + 1); // Increment the date by 1 day
      if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
        // If the day is not a Sunday (0) or Saturday (6)
        datelist.push(dateChanger(endDate))
        count++;
      }
    }
    //console.log(datelist)
    // setShowAddStudentButton(false)
    setCalenderEventDates(datelist)
    return endDate.toISOString().split('T')[0]; // Return the date in "yyyy-mm-dd" format
  }

  function getStartDateMonWedFri(date, days) {
    console.log("hhhhhhhhh", date, days)
    if (new Date().getDay() === 1 || new Date().getDay() === 3 || new Date().getDay() === 5) {
      let result = new Date();
      console.log("the result iis", dateChanger(result))
      setStartDate(dateChanger(result))
      return result

    } else {
      let result = new Date();
      result.setDate(result.getDate() + days);
      console.log("the result is", result)
      return result;
    }
  }

  function getStartDateTueThurSat(date, days) {
    console.log("hhhhhhhhh", date, days)
    if (new Date().getDay() === 2 || new Date().getDay() === 4 || new Date().getDay() === 6) {
      let result = new Date();
      console.log("the result iis", dateChanger(result))
      return result

    } else {
      let result = new Date();
      result.setDate(result.getDate() + days);
      console.log("the result is", result)
      return result;
    }
  }

  function addWorkingDaysTueThursSat(date, numDays) {
    console.log("the batches are", batchData.batchdata)

    const allBatchData = batchData.batchdata
    const dates = dateChanger(new Date())

    // let sk = allBatchData.filter((ele) => {
    //   return ele.Repeat === `Tue-Thur-Sat` && ele.batchProgress != "Cancelled" && ele.batchProgress != "Discontinued"
    // })

    // if (sk.length > 0) {
    //   const startTime = AddDurationToTime(sk[sk.length - 1].EndTime, '15M')
    //   setStartTime(startTime)
    //   const endTime = AddDurationToTime(sk[sk.length - 1].EndTime, '75M')
    //   setEndTime(endTime)
    // } else {
    //   setStartTime("12:30")
    //   setEndTime("13:30")
    // }

    let batches = allBatchData.filter((ele) => {
      return ele.Repeat === `Tue-Thur-Sat` && ele.batchProgress != "Cancelled" && ele.batchProgress != "Discontinued"
    })

    const sortedBatches = batches.sort((a, b) => {
      const timeA = new Date(`1970-01-01T${a.EndTime}:00`);
      const timeB = new Date(`1970-01-01T${b.EndTime}:00`);
      return timeA - timeB; // For descending order
    });

    console.log("0000000002222", sortedBatches)

    if (sortedBatches.length > 0) {
      const startTime = AddDurationToTime(sortedBatches[sortedBatches.length - 1].EndTime, '15M')
      setStartTime(startTime)
      const endTime = AddDurationToTime(sortedBatches[sortedBatches.length - 1].EndTime, '75M')
      setEndTime(endTime)
    } else {
      setStartTime("12:30")
      setEndTime("13:30")
    }


    const startDate = new Date(getStartDateTueThurSat(date, 1));
    const endDate = new Date(startDate);
    setStartDate(startDate.toISOString().split('T')[0])
    let count = 1;
    let datelist = [dateChanger(startDate)]
    while (count < numDays) {
      endDate.setDate(endDate.getDate() + 1);
      // Increment the date by 1 day
      if (endDate.getDay() !== 1 && endDate.getDay() !== 3 && endDate.getDay() !== 5 && endDate.getDay() !== 0) {
        // If the day is not a Sunday (0) or Saturday (6)
        datelist.push(dateChanger(endDate))
        count++;
      }
    }
    console.log(datelist,)
    // setShowAddStudentButton(false)
    setCalenderEventDates(datelist)
    return endDate.toISOString().split('T')[0]; // Return the date in "yyyy-mm-dd" format
  }

  function addSpecificday(date, DAY) {
    console.log("the day is", date, DAY)
    const startDate = new Date(date);
    const endDate = new Date(startDate);
    console.log("ttt is", startDate, endDate)
    let count = 1;
    let datelist = [dateChanger(startDate)]
    while (count < DAY) {
      console.log("th end date is", startDate.getDay())
      endDate.setDate(endDate.getDate() + 1); // Increment the date by 1 day
      if (endDate.getDay() === startDate.getDay()) {
        // If the day is a Specific day (2)
        datelist.push(dateChanger(endDate))
        count++;
      }
    }
    console.log("the datelist is", datelist)
    // setShowAddStudentButton(false)
    setCalenderEventDates(datelist)
    return endDate.toISOString().split('T')[0]; // Return the date in "yyyy-mm-dd" format
  }

  function addWorkingDaysMonWedFri(date, numDays) {

    console.log("it comes inside the addworkingDaysMonWedFri the batches are", batchData.batchdata)

    const allBatchData = batchData.batchdata
    const dates = dateChanger(new Date())

    let batches = allBatchData.filter((ele) => {
      return ele.Repeat === `Mon-Wed-Fri` && ele.batchProgress != "Cancelled" && ele.batchProgress != "Discontinued"
    })

    const sortedBatches = batches.sort((a, b) => {
      const timeA = new Date(`1970-01-01T${a.EndTime}:00`);
      const timeB = new Date(`1970-01-01T${b.EndTime}:00`);
      return timeA - timeB; // For descending order
    });

    console.log("00000000000", sortedBatches)

    if (sortedBatches.length > 0) {
      const time = new Date(`1970-01-01T${batches[batches.length - 1].EndTime}:00`)
      // Create a Date object for January 1st, 1970
      const specificDate = new Date('1970-01-01T00:00:00Z');

      // Set the time to 9:00 PM (21:00)
      specificDate.setHours(21);   // 9 PM
      specificDate.setMinutes(0);  // 00 minutes
      specificDate.setSeconds(0);  // 00 seconds
      specificDate.setMilliseconds(0);  // 000 milliseconds

      // Convert to ISO string to check the result
      const isoString = specificDate.toISOString();

      console.log('Date set to:', specificDate);
      console.log('ISO String:', isoString);

      if (time >= specificDate) {
        setStartTime("12:30")
        setEndTime("13:30")
      } else {
        if (sortedBatches.length > 0) {
          const startTime = AddDurationToTime(sortedBatches[sortedBatches.length - 1].EndTime, '15M')
          setStartTime(startTime)
          const endTime = AddDurationToTime(sortedBatches[sortedBatches.length - 1].EndTime, '75M')
          setEndTime(endTime)
        } else {
          setStartTime("12:30")
          setEndTime("13:30")
        }
      }
    }





    const startDate = new Date(getStartDateMonWedFri(date, 1));
    const endDate = new Date(startDate);
    setStartDate(startDate.toISOString().split('T')[0])
    let count = 1;
    let datelist = [dateChanger(startDate)]
    while (count < numDays) {
      endDate.setDate(endDate.getDate() + 1);
      // Increment the date by 1 day
      if (endDate.getDay() !== 2 && endDate.getDay() !== 4 && endDate.getDay() !== 6 && endDate.getDay() !== 0) {
        // If the day is not a Sunday (0) or Saturday (6)
        datelist.push(dateChanger(endDate))
        count++;
      }
    }
    console.log(datelist)
    // setShowAddStudentButton(false)
    setCalenderEventDates(datelist)
    return endDate.toISOString().split('T')[0]; // Return the date in "yyyy-mm-dd" format
  }

  function addSpecificday(date, DAY) {
    const startDate = new Date(date);
    const endDate = new Date(startDate);
    let count = 1;
    let datelist = [dateChanger(startDate)]
    while (count < DAY) {
      endDate.setDate(endDate.getDate() + 1); // Increment the date by 1 day
      if (endDate.getDay() === startDate.getDay()) {
        // If the day is a Specific day (2)
        datelist.push(dateChanger(endDate))
        count++;
      }
    }
    // setShowAddStudentButton(false)
    setCalenderEventDates(datelist)
    return endDate.toISOString().split('T')[0]; // Return the date in "yyyy-mm-dd" format
  }

  const ShowInCalender = (calenderEventDatesforcalender) => {
    setShowAddStudentButton(true)
    //doesRangeOverlapWithPreviousData(startdate, enddate, starttime, endtime)
    const coursedfulldata = courseOptions.find(obj => obj.course_name === course);
    let events = []
    for (let i = 0; i < coursedata.total_class; i++) {
      events.push({
        title: coursedfulldata.course_name + "(" + (coursedata.number_of_batches + 1).toString() + ")" + "(" + (i + 1).toString() + ")",
        start: calenderEventDatesforcalender[i] + "T" + starttime + ":00",
        end: calenderEventDatesforcalender[i] + "T" + endtime + ":00",
        scheduleNumber: i,
      })
    }
    setCalenderEvents(events.concat(prevEvents))
    return events
  }
  const ShowdefaultDateInCalender = () => {
    const coursedfulldata = courseOptions.find(obj => obj.course_name === course);
    let events = []
    for (let i = 0; i < Customdatelist.length; i++) {
      events.push({
        title: coursedfulldata.course_name + "(" + (coursedata.number_of_batches + 1).toString() + ")" + "(" + (i + 1).toString() + ")",
        start: Customdatelist[i]["startDateTime"],
        end: Customdatelist[i]["endDateTime"],
        scheduleNumber: parseInt(i) + 1,
      })
    }
    setCalenderEvents(events.concat(prevEvents))
    return events
  }
  const setDatainCorrectFormatForStoring = (meetlink) => {

    const response = ShowInCalender(calenderEventDates)
    let changeddata = []
    for (let i = 0; i < response.length; i++) {
      changeddata.push({
        startDateTime: response[i]["start"],
        endDateTime: response[i]["end"],
        scheduleNumber: response[i]["scheduleNumber"] + 1,
        status: "Scheduled",
        meet_link: meetlink
      })
    }
    return changeddata
  }
  const setDefaultDatainCorrectFormatForStoring = (meetlink) => {
    let events = Customdatelist
    for (let i = 0; i < Customdatelist.length; i++) {
      events[i]["scheduleNumber"] = i + 1
      events[i]["meet_link"] = meetlink
    }
    return events
  }

  const NavigatetoStudentAddPage = (meetLink) => {


    console.log("the generated Mettlink is", meetLink)
    setMeetLink(meetLink)
    const changeddata = Customdatelist.length === 0 ? setDatainCorrectFormatForStoring(meetLink) : setDefaultDatainCorrectFormatForStoring(meetLink)
    // const userdata = JSON.parse(sessionStorage.getItem('UserData'))
    const userdata = JSON.parse(sessionStorage.getItem('SelectedMentor'));
    const coursedfulldata = courseOptions.find(obj => obj.course_name === course);
    const params = {
      mentorId: userdata.user_id,
      // BatchName: coursedfulldata.course_name + "-" + coursedfulldata.course_id + "-" + (coursedata.number_of_batches + 1),
      BatchName: coursedfulldata.course_name + "-" + (coursedata.number_of_batches + 1),
      BatchId: coursedfulldata.course_id + "-" + (coursedata.number_of_batches + 1),
      batchProgress: "Scheduled",
      courseId: coursedfulldata.course_id,
      courseName: coursedfulldata.course_name,
      EndDate: enddate,
      mentorName: userdata.user_name,
      StartDate: startdate,
      StartTime: starttime,
      EndTime: endtime,
      Repeat: recurrent,
      ClassSchedule: changeddata
    }
    navigate("/codomo/admin/addstudent", { state: { data: params } })
  }


  const addBatchToTheDataBase = async (meetLink) => {

    console.log("the generated Mettlink is", meetLink)
    setLoading(true)
    setMeetLink(meetLink)
    const changeddata = Customdatelist.length === 0 ? setDatainCorrectFormatForStoring(meetLink) : setDefaultDatainCorrectFormatForStoring(meetLink)
    // const userdata = JSON.parse(sessionStorage.getItem('UserData'))

    const batchStartDate = new Date(changeddata[0].startDateTime).toISOString().split('T')[0];
    const batchEndDate = new Date(changeddata[changeddata.length - 1].startDateTime).toISOString().split('T')[0];
    const userdata = JSON.parse(sessionStorage.getItem('SelectedMentor'));
    const coursedfulldata = courseOptions.find(obj => obj.course_name === course);
    const params = {
      mentorId: userdata.user_id,
      // BatchName: coursedfulldata.course_name + "-" + coursedfulldata.course_id + "-" + (coursedata.number_of_batches + 1),
      BatchName: coursedfulldata.course_name + "-" + (coursedata.number_of_batches + 1),
      BatchId: coursedfulldata.course_id + "-" + (coursedata.number_of_batches + 1),
      batchProgress: "Scheduled",
      courseId: coursedfulldata.course_id,
      courseName: coursedfulldata.course_name,
      EndDate: batchEndDate,
      mentorName: userdata.user_name,
      StudentsEndrolled: addedStudents,
      StartDate: batchStartDate,
      // StartTime: starttime,
      // EndTime: endtime,
      // Repeat: recurrent,
      ClassSchedule: changeddata
    }

    const responsedata = await ConstructorEventPost(AddNewBatch, params)
    if (responsedata === "NewBatchAdded") {
      navigate("/codomo/admin/createbatch")
      notifySuccess("Batch Created Succesfully")
    }
    else {
      notifyError("Error while creating batch")
    }
    console.log(responsedata, "response")
    setSubmitloading(false)
    console.log("The last paaarams is", params)
  }

  function scheduleUsingCustomDates() {
    console.log("custom batch is", batchData.batchdata)
    console.log("custom the startdate is",)
    setCustomStartTime(starttime)
    setCustomDate(startdate)
    setCustomBatchShedule(true)

  }


  function addStudents() {
    setIsAddStudentClicked(true)
  }
  const fetchdata = async () => {
    const responsedata = await ConstructorEventGet(FetchStudentDetails)
    setEnrolled(responsedata);

    const userStudents = responsedata.filter((ele) => {
      return ele.user_type === "student"
    })
    setFilteredData(userStudents)

    setStudentDetails(userStudents)
    setLoading(false)
  }


  const columns = [
    {
      title: "Students",
      dataIndex: "name",
      // sorter: (a, b) => a.name.length - b.name.length,
    }
  ];

  const handleSearch = (e) => {
    setSearchValue(e.target.value)

    const userStudents = studentDetails.filter((ele) => {
      return ele.user_type === "student"
    })

    const searchedValue = userStudents.filter((ele) => {
      return ele.name.toLowerCase().includes(e.target.value.toLowerCase()) || ele.mobile.includes(e.target.value) || ele.email.toLowerCase().includes(e.target.value.toLowerCase())
    })

    setFilteredData(searchedValue)


    console.log("the eleeeee is", searchedValue)
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [enrolledRowKeys, setEnrolledRowKeys] = useState([]);

  const onEnrolledChange = (newEnrolledRowKeys) => { setEnrolledRowKeys(newEnrolledRowKeys); };
  const onSelectChange = (newSelectedRowKeys) => { setSelectedRowKeys(newSelectedRowKeys); };
  const enrolledrowSelection = {
    selectedRowKeys: enrolledRowKeys, onChange: onEnrolledChange, getCheckboxProps: (record) => ({
      disabled: studentsAlreadyInBatch.includes(record.key), // Disable checkbox if the student is preselected
    }),
  };
  const selectedrowSelection = { selectedRowKeys: selectedRowKeys, onChange: onSelectChange };

  const RemoveStudent = () => {
    const filteredStudents = addedStudents.filter(item => !selectedRowKeys.includes(item.key));
    setAddedStudents(filteredStudents);
    setSelectedRowKeys([]);
  }


  const AddStudent = () => {
    const foundObjects = [];
    enrolledRowKeys.forEach(key => {
      const foundObject = enrolled.find(item => item.key === key);
      const duplicate = addedStudents.find(item => item.key === key);
      if (foundObject && !duplicate) {
        foundObjects.push(foundObject);
      }
    });
    setAddedStudents([...new Set([...addedStudents, ...foundObjects])])
    setEnrolledRowKeys([])
  }

  const studentsAdded = () => {
    setIsAddStudentClicked(false)
    console.log("the addes students are", addedStudents)
    const emails = addedStudents.map(student => student.email);
    console.log("The emails of the added students are:", emails);
    setStudentEmail(emails)
  }


  return (
    <>
      <Header />
      {loading ?
        (
          <div className="spinner">
            <div className="spinner-wrapper">
              <ClassicSpinner size={50} color="black" loading={loading} /> {/* Fallback spinner */}
            </div>
          </div>
        ) :
        (isAddStudentClicked ?

          submitloading ?
            <div className="spinner">
              < div className="spinner-wrapper">
                <ClassicSpinner size={50} color="black" loading={submitloading} />
              </div >
            </div >
            :
            <div className="nav-tab-wrapper tabs  section-padding">
              <div className="container">
                <div className=" grid grid-cols-12 gap-[30px]">
                  <div className="xl:col-span-5 lg:col-span-5 col-span-5 max-h-100">
                    <div className="bg-white shadow-box7 p-8 rounded-md">
                      <Input
                        placeholder="Search by Name,Phone or Email"
                        className="from-control"
                        value={searchValue}
                        onChange={handleSearch}
                        style={{ marginBottom: 16 }}

                      />
                      <Table
                        rowSelection={enrolledrowSelection}
                        className=""
                        columns={columns}
                        dataSource={filteredData.filter(search => !addedStudents.find(item => item.key === search.key))}
                        pagination={filteredData.filter(search => !addedStudents.find(item => item.key === search.key)).length > 9 ? true : false}
                        rowKey={(record) => record.key}
                        loading={{ indicator: <div><ClassicSpinner size={50} color="black" loading={loading} /> </div>, spinning: loading }}
                      />
                    </div>
                  </div>
                  <div className="xl:col-span-2 lg:col-span-2 col-span-2 max-h-100">
                    <div className="bg-white shadow-box7 p-4 rounded-md">
                      <div className="grid gap-4 mt-4">
                        <button className={enrolledRowKeys.length > 0 ? "btn btn-primary mt-4" : "btn btn-disabled mt-4"} disabled={enrolledRowKeys.length > 0 ? false : true} onClick={AddStudent}>Add</button>
                        <button className={selectedRowKeys.length > 0 ? "btn btn-secondary mt-4" : "btn btn-disabled mt-4"} disabled={selectedRowKeys.length > 0 ? false : true} onClick={RemoveStudent} >Remove</button>
                        <button className={addedStudents.length > 0 ? "btn btn-black mt-4" : "btn btn-disabled mt-4"} disabled={addedStudents.length > 0 ? false : true} onClick={studentsAdded}>Done</button>
                      </div>


                    </div>
                  </div>
                  <div className="xl:col-span-5 lg:col-span-5 col-span-5 max-h-100">
                    <div className="bg-white shadow-box7 p-8 rounded-md">
                      <div className="from-control mb-4">Batch Count :{addedStudents.length}</div>
                      <Table
                        rowSelection={selectedrowSelection}
                        className=""
                        columns={columns}
                        dataSource={addedStudents}
                        pagination={addedStudents.length > 9 ? true : false}
                        rowKey={(record) => record.key}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          : <div className="nav-tab-wrapper tabs section-padding">
            <div className="container">
              <div className="grid grid-cols-12 gap-[30px]">
                {/* Adjusted grid columns for 30% and 70% distribution */}
                <div className="lg:col-span-4 col-span-12">
                  <div className="mini-title">New Batch</div>
                  <h4 className="column-title" style={{ fontSize: "30px", lineHeight: "1", marginBottom: "5px" }}>
                    Start a Batch <span className="shape-bg">Now</span>
                  </h4>
                  <div >
                    Enhance your scheduling process by choosing a course and seamlessly scheduling it on the available dates for your batch!
                  </div>
                  <ul className="list-item space-y-4 pt-2 mt-4">
                    <li className="flex">
                      <div className="rounded-md w-full">
                        {showeventmenu ? (
                          <div className="bg-white shadow-box7 p-4 rounded-md w-full">
                            <div className="flex-none mr-6  focus:ring-0 flex items-center">
                              Course: {course}
                            </div>
                            <div className="flex-none mr-6  focus:ring-0 flex items-center">
                              Total Class: {coursedata.total_class}
                            </div>
                            <div className="flex-none mr-6 focus:ring-0 flex items-center">
                              Batch: {coursedata.number_of_batches + 1}
                            </div>
                            {CustomBatchShedule ? (
                              <div>
                                <div className="mt-2">
                                  <div className="grid grid-cols-3 md:grid-cols-3 gap-0.5">
                                    {Customdatelist.map((data, index) => (
                                      <span key={index} className="email-tag" style={{
                                        padding: '2px 4px', // Reduced padding
                                        fontSize: '9px',    // Smaller font size
                                        marginBottom: '4px', // Spacing between tags
                                        borderRadius: '4px', // Optional for rounded corners
                                        display: 'inline-block', // Use inline-flex for better alignment
                                        alignItems: 'center',
                                        maxWidth: 'fit-content'   // Center-align contents
                                      }}>
                                        {data["startDateTime"]}
                                        <span
                                          className="remove-tag bg-lightgreen"
                                          onClick={() => removeDateFromCustomDateList(data["startDateTime"])}
                                          style={{
                                            marginLeft: '5px',  // Space between date and remove button
                                            cursor: 'pointer',
                                            display: 'inline-block'   // Change cursor for remove button
                                          }}
                                        >
                                          &times;
                                        </span>
                                      </span>
                                    ))}
                                  </div>

                                </div>

                                <div className="flex-none mr-6 from-control focus:ring-0 flex items-center" style={{
                                  padding: "5px 5px",
                                }} >
                                  <input

                                    className="from-control-edit focus:ring-0"
                                    required
                                    type="date"
                                    onChange={(e) => setCustomDate(e.target.value)}

                                  />
                                  <input

                                    className="from-control-edit focus:ring-0"
                                    required
                                    type="time"
                                    onChange={(e) => {
                                      setCustomStartTime(e.target.value);
                                      setCustomEndTime(
                                        AddDurationToTime(
                                          e.target.value,
                                          coursedata.class_duration
                                        )
                                      );
                                    }}
                                  />
                                  {Customdatelist.length < coursedata.total_class ? (
                                    <div className="resend-otp" onClick={IncludeInCustomDateList}>
                                      Add
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <button
                                  className="btn btn-black mt-4 mr-2" style={{ fontSize: "14px", lineHeight: "0.8" }}
                                  onClick={ShowdefaultDateInCalender}
                                >
                                  Show In Calender
                                </button>
                                {Customdatelist.length < coursedata.total_class ? (
                                  ""
                                ) : (
                                  <button
                                    className="btn btn-primary mt-2 ml-2"
                                    style={{ fontSize: "14px", lineHeight: "0.8" }}
                                    onClick={createGoogleMeet}
                                  >
                                    Submit
                                  </button>
                                )}
                              </div>
                            ) : (
                              <div>
                                <div className="flex-none mr-6  from-control focus:ring-0 flex items-center" style={{
                                  padding: "5px 5px",
                                }}>
                                  <input
                                    value={startdate}
                                    className="from-control-edit focus:ring-0"
                                    required
                                    type="date"

                                    onChange={(e) => {
                                      setStartDate(e.target.value);
                                      setEndDate(
                                        recurrent === "Mon-Wed-Fri"
                                          ? addWorkingDaysMonWedFri(
                                            e.target.value,
                                            coursedata.total_class
                                          )
                                          : addWorkingDaysTueThursSat(
                                            e.target.value,
                                            coursedata.total_class
                                          )
                                      );
                                    }}
                                    readOnly
                                  />{" "}
                                  {" - "}
                                  <input
                                    value={enddate}
                                    className="from-control-edit focus:ring-0"
                                    required
                                    type="date"
                                    onChange={(e) => setEndDate(e.target.value)}
                                    readOnly
                                  />
                                </div>
                                <div className="flex-none mr-6 mt-2 from-control focus:ring-0 flex items-center" style={{
                                  padding: "5px 5px",
                                }}>
                                  <input
                                    value={starttime}
                                    className="from-control-edit focus:ring-0"
                                    required
                                    type="time"
                                    onChange={(e) => {
                                      setStartTime(e.target.value);
                                      setEndTime(
                                        AddDurationToTime(
                                          e.target.value,
                                          coursedata.class_duration,
                                        )
                                      );
                                    }}
                                    readOnly
                                  />{" "}
                                  {" - "}
                                  <input
                                    value={endtime}
                                    className="from-control-edit focus:ring-0"
                                    required
                                    type="time"
                                    onChange={(e) => setEndTime(e.target.value)}
                                    readOnly
                                  />
                                </div>
                                <div className="flex-none mr-6 mt-2 from-control focus:ring-0 flex items-center" style={{
                                  padding: "5px 5px",
                                }}>
                                  <select
                                    className="from-control-edit focus:ring-0"
                                    value={recurrent}
                                    onChange={(e) => {
                                      setRecurrent(e.target.value);
                                      setEndDate(
                                        e.target.value === "Mon-Wed-Fri"
                                          ? addWorkingDaysMonWedFri(
                                            startdate,
                                            coursedata.total_class
                                          )
                                          : addWorkingDaysTueThursSat(
                                            startdate,
                                            coursedata.total_class
                                          )
                                      );
                                    }}
                                    required
                                  >
                                    <option>Select Days for classes</option>
                                    <option value="Mon-Wed-Fri">
                                      Every Week (Mon, Wed, Fri)
                                    </option>
                                    <option value="Tue-Thur-Sat">
                                      Every Week (Tues, Thurs, Sat)
                                    </option>
                                  </select>
                                </div>
                                <div className="resend-otp mt-4" onClick={scheduleUsingCustomDates}>
                                  Schedule Batch on Custom dates
                                </div>
                                <div className="flex justify-between mt-2">
                                  <button
                                    className="btn btn-small btn-black mt-2 " style={{ fontSize: "14px", lineHeight: "0.8" }}
                                    onClick={() => ShowInCalender(calenderEventDates)}
                                  >
                                    Show In Calender
                                  </button>
                                  {showAddStudentButton ? (
                                    <button
                                      style={{ fontSize: "14px", lineHeight: "0.8" }}
                                      className="btn btn-small btn-primary mt-2"
                                      onClick={createGoogleMeet}
                                    >
                                      Add Students
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="mt-3">
                            <div className="flex-none mr-6 from-control-batch focus:ring-0 flex items-center">
                              <select
                                className="from-control-batch-edit focus:ring-0"
                                value={course}
                                onChange={(e) => setCourse(e.target.value)}
                                required
                              >
                                <option value="">Select Course</option>
                                {courseOptions.map((item, index) => (
                                  <option value={item.course_name}>{item.course_name}</option>
                                ))}
                              </select>


                            </div>
                            {addedStudents.length > 0 ?
                              <div className="mt-2">
                                <h5>Added Students:</h5>
                                <ul>
                                  {addedStudents.map((student, index) => (
                                    <li key={index}>{student.name}</li>
                                  ))}
                                </ul>
                              </div> : ""}
                            <button
                              className="btn btn-primary mt-4 mr-2"
                              style={{ fontSize: "14px", lineHeight: "0.8" }}
                              onClick={addStudents}
                            >
                              Add Students
                            </button>

                            {addedStudents.length > 0 ?
                              <button
                                className="btn btn-black mt-4 mr-2 ml-2"
                                style={{ fontSize: "14px", lineHeight: "0.8" }}
                                onClick={course && fetchCourseDetails}
                              >Schedule Class</button> : ""}

                          </div>

                        )}

                      </div>
                    </li>
                  </ul>
                </div>
                {/* 70% width for the calendar */}
                <div className="lg:col-span-8 col-span-12">
                  <div className="bg-white shadow-box7 p-8 rounded-md">
                    <FullCalendar
                      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                      initialView="timeGridWeek"
                      headerToolbar={{
                        center: "dayGridMonth,timeGridWeek",
                      }}
                      weekends={true}
                      selectable={true}

                      select={(clickInfo) => handleDateSelect(clickInfo)}

                      dayMaxEvents={0}
                      events={calenderEvents}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>)}
    </>
  );

};

export default AddBatch;
