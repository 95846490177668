import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../assets/css/newacitivity.css";
import { SaveHtmlStructure } from "../../api/aws-api";
import { FetchHomePageDetails, Uploadhex } from "../../api/aws-api";
import { ConstructorEventPost } from "../../Event/event";
import Header from "../Header";
import Lottie from "lottie-react";
import animationData from "../../assets/animation/Animation - 1727426684669.json";



export function Appone() {
  const getHtmlCode = useRef();
  const [htmlCode, setHtmlCode] = useState();
  const location = useLocation();
  const user_id = location.state?.user_id; 
  const [workspaceItems, setWorkspaceItems] = useState([]);
  
  const [activitystarted, setActivitystarted] = useState(false);
  const [SelectedScreen, setSelectedScreen] = useState("");
  const [ClickedNext, setClickedNext] = useState(false);
  const [dragging, setDragging] = useState(true);
  const [loading, setLoading] = useState(false);
  const [divtochangeContent, setDivtochangeContent] = useState([]);
  const [screen, setScreen] = useState("Left");
  const [selectedAudio, setSelectedAudio] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [isMobileClicked, setMobileClicked] = useState(false); // New state
  const [selectedAttribute, setSelectedAttribute] = useState(false);
  const [propertyScreen, setPropertyScreen] = useState("");
  const [selectedPropertyColor, setPropertySelectedColor] = useState("");
  const [valuePropertyDropdown, setPropertyValueDropdown] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [ValueDropdown, setValueDropdown] = useState("");
  const [selectedpopup, setSelectedpopup] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [userId, setUserId] = useState(null);

  
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('UserKey'));
    if (userData && userData.user_id) {
      setUserId(userData.user_id);  // Store user_id in state
      console.log(`User ID: ${userData.user_id}`);
    }
  }, []);
  console.log('fdfddd',userId)

  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", e.target.id);
  };
  

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedItemId = e.dataTransfer.getData("text/plain");

    const newWorkspaceItem = {
      id: workspaceItems.length + 1,
      type: droppedItemId,
      selectedValue: "",  // Add this field to track dropdown selections
    };

    setWorkspaceItems([...workspaceItems, newWorkspaceItem]);
  };

// Handle dropdown changes by updating the relevant workspace item's selected value
const handleDropdownChange = (id, value) => {
  setWorkspaceItems((prevItems) =>
    prevItems.map((item) =>
      item.id === id ? { ...item, selectedValue: value } : item
    )
  );
};


  // Function to generate Python code based on workspace items
  const generatePythonCode = () => {
    let code = "";
    workspaceItems.forEach((item) => {
      if (item.type === "setProperty") {
        if (item.selectedValue === "Heart") {
          code += "basic.show_icon(IconNames.HEART)\n";
        } else if (item.selectedValue === "Beating Heart") {
          code += "basic.show_icon(IconNames.SMALL_HEART)\n";
        }
      } else if (item.type === "setScreen") {
        if (item.selectedValue === "Duck") {
          code += "basic.show_icon(IconNames.DUCK)\n";
        } else if (item.selectedValue === "Tortoise") {
          code += "basic.show_icon(IconNames.TORTOISE)\n";
        } else if (item.selectedValue === "Giraffe") {
          code += "basic.show_icon(IconNames.GIRAFFE)\n";
        } else if (item.selectedValue === "Cow") {
          code += "basic.show_icon(IconNames.COW)\n";
        }
        
        // Add cases for other animals...
      } else if (item.type === "playSound") {
        code += `music.play_melody('${item.selectedValue}', 120)\n`;
      } else if (item.type === "Number") {
        code += `basic.show_number(${item.selectedValue})\n`;
      }
    });
    return code;
  };
  const convertPythonToHex = async (pythonCode) => {
    setLoading(true);
    try {
      const params = {
        python_code: pythonCode,
        user_id: userId,
      };
  
      // Send the request to the Uploadhex API
      const response = await ConstructorEventPost(Uploadhex, params);
  
      if (response && response.body) {
        const parsedBody = JSON.parse(response.body);
  
        if (parsedBody.hexFile && parsedBody.filename) {
          // Decode base64 hex file
          const byteCharacters = atob(parsedBody.hexFile);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
  
          // Create a Blob from the byteArray
          const blob = new Blob([byteArray], { type: 'application/octet-stream' });
  
          // Create a URL for the Blob
          const blobUrl = window.URL.createObjectURL(blob);
  
          // Create a hidden anchor element and trigger the download
          const a = document.createElement("a");
          a.href = blobUrl;
          a.download = parsedBody.filename; // Set file name for download
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
  
          // Clean up the Blob URL
          window.URL.revokeObjectURL(blobUrl);
        } else {
          throw new Error("Hex file not found in the parsed response body.");
        }
      } else {
        throw new Error("Hex file not found in response.");
      }
    } catch (error) {
      console.error("Error converting Python to Hex:", error);
    }
    finally{
      setLoading(false);
    }
  };
  
  
  

  // Handle the download button click
  const handleDownload = async () => {
    const pythonCode = generatePythonCode();
    await convertPythonToHex(pythonCode);  // Send the Python code to the API and download the hex
    console.log(generatePythonCode)
  };
 



  const handleDragOver = (e) => {
    e.preventDefault();
  };

  function handleHtmlCode() {
    const data = getHtmlCode.current.outerHTML;
    setHtmlCode(data);
  }

  const popupvalue = () => {
    setSelectedpopup(!selectedpopup);
  };

  const handleMobileClick = () => {
    setMobileClicked(true);
    if (activitystarted && selectedOption === "Click" && isMobileClicked) {
      var audio = new Audio(selectedAudio);
      audio.play();
      setScreen(SelectedScreen);
      if (propertyScreen && selectedPropertyColor && valuePropertyDropdown) {
        console.log(propertyScreen);
        console.log(selectedPropertyColor);
        console.log(valuePropertyDropdown);
        setSelectedScreen(propertyScreen);
        setSelectedColor(selectedPropertyColor);
        setValueDropdown(valuePropertyDropdown);
        setScreen(propertyScreen);
      }
    }
  };

  return (
    <>
    <Header />

    
    <div className="container" >
      <div>

       

        <div className="lg:col-span-9 col-span-12 mt-5">
          <i className="fa-duotone fa-up-down-left-right fa-spin fa-spin-reverse"></i>
          <div   style={{marginTop:"100px"}}>
            
            
          </div>

          <div className="row">
            <div className="col-6">
              <div className="box">
                <div className="box-head">Toolbox</div>

                <div className="box-content p-3">
                  <div
                    className="pin"
                    draggable="true"
                    id="setProperty"
                    onDragStart={handleDragStart}
                  >
                    <div className="divtochange flex">Set Property </div>
                  </div>

                  <div
                    className="pin"
                    draggable="true"
                    id="setScreen"
                    onDragStart={handleDragStart}
                  >
                    <div className="divtochange flex">setScreen </div>
                  </div>

                  <div
                    className="pin"
                    draggable="true"
                    id="playSound"
                    onDragStart={handleDragStart}
                  >
                    <div className="divtochange flex">playSound </div>
                  </div>
                  <div
                    className="pin"
                    draggable="true"
                    id="Number"
                    onDragStart={handleDragStart}
                  >
                    <div className="divtochange flex">Number </div>
                  </div>

                  <div
                    className="plug-board work-space"
                    draggable="true"
                    id="onEvent"
                    onDragStart={handleDragStart}
                  >
                    <div className="plug-board-top">
                      OnEvent (id, type, callback)
                      <div className="function">Function</div>
                    </div>

                   

                  </div>
                </div>
              </div>
            </div>
            {/* <button
                className="button-12 mt-2"
                style={{ textAlign: "center" }}
                
              >
                RESET
              </button> */}

            <div className="col-9">
              <div className="box">
                <div className="box-head">Workspace</div>
                <div className="box-content p-3" onDrop={handleDrop} onDragOver={handleDragOver}>
                  { loading ? (<div className="spinner" ><Lottie style={{ height:"300px"}} animationData={animationData} />
                  </div>):(
                <button className="getHtmlCodeBtn" onClick={handleDownload} style={{ marginBottom:"20px"}}>
              Download
            </button>)}
                  {workspaceItems.map((item) => (
                    <div key={item.id} className="divtochange flex">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="10em" height="10em" viewBox="0 0 36 36" onClick={handleDownload}>
                        <path fill="#dd2e44" d="M35.885 11.833c0-5.45-4.418-9.868-9.867-9.868c-3.308 0-6.227 1.633-8.018 4.129c-1.791-2.496-4.71-4.129-8.017-4.129c-5.45 0-9.868 4.417-9.868 9.868c0 .772.098 1.52.266 2.241C1.751 22.587 11.216 31.568 18 34.034c6.783-2.466 16.249-11.447 17.617-19.959c.17-.721.268-1.469.268-2.242"  onClick={handleDownload}></path>
                      </svg> */}
                      {/* <button onClick={()=>{loadFile(),handleDownload()}}>Load HEX File</button> */}
                      {/* <button onClick={() => { loadFile(); handleDownload(); }}>Load HEX File</button> */}
                      {item.type === "setProperty" && (
                        
                        <div className="pin" style={{marginLeft:"25px"}}>
                          setProperty (
                          <select className="dropdown" value={item.selectedValue}
                            onChange={(e) => handleDropdownChange(item.id, e.target.value)}
                          >
                            <option>Select</option>
                            <option value="Heart">Heart</option>
                            <option value="Beating Heart">Beating Heart</option>
                          </select>
                          )
                        </div>
                      )}



                      {item.type === "setScreen" && (
                        <div className="pin" style={{marginLeft:"25px"}}>
                          setScreen (
                          <select className="dropdown" value={item.selectedValue}
                            onChange={(e) => handleDropdownChange(item.id, e.target.value)}
                          >
                            <option>Select</option>
                            <option value="Duck">Duck</option>
                            <option value="Tortoise">Tortoise</option>
                            <option value="Giraffe">Giraffe</option>
                            <option value="Cow">Cow</option>
                          </select>
                          )
                        </div>
                      )}

                      {item.type === "playSound" && (
                        <div className="pin" style={{marginLeft:"25px"}}>
                          playSound (
                          <select className="dropdown" value={item.selectedValue}
                            onChange={(e) => handleDropdownChange(item.id, e.target.value)}
                          >
                            <option value="">Select Sound</option>
                            <option value="sword">Sword</option>
                            <option value="skull">Skull</option>
                            <option value="shake">Shake</option>

                          </select>
                          )
                        </div>
                      )}
                      {item.type === "Number" && (
  <div className="pin" style={{ marginLeft: "25px" }}>
    Number (
    <input
                            type="text"
                            value={item.selectedValue}
                            
                            onChange={(e) => handleDropdownChange(item.id, e.target.value)}
                          />
    )
  </div>
)}


                      {item.type === "onEvent" && (
                        <div className="plug-board work-space">
                          <div className="plug-board-top">
                            OnEvent (id, type, callback)
                            <div className="function">Function</div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {selectedpopup && (
            <div className="modal">
              <div className="overlay">
                <div className="modal-content">
                  {htmlCode}
                  <button className="btn" style={{ marginTop: "30px" }} onClick={popupvalue}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
    
    </>
  );
}

export default Appone;