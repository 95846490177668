import React, { useState, useRef, useEffect } from "react";
import Header from "../Header";
import { ClassicSpinner, MagicSpinner } from "react-spinners-kit";
import { useNavigate, useLocation, Link } from "react-router-dom"
import { FetchAllCourses, FetchMentorDetails, AssignCourseToMentor, StudentAdmiration } from "../../api/aws-api";
import { ConstructorEventGet, ConstructorEventPost } from "../../Event/event";
import { notifySuccess, notifyError } from "../../Common/CommonFunction";


const AssiginingCourseToMentor = () => {

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const [courses, setCourses] = useState([])
    const [mentors, setMentors] = useState([])
    const checkboxesRef = useRef([]);
    const radiosRef = useRef([]);

    const [selectedMentor, setSelectedMentor] = useState("");
    const [selectedCourse, setSelectedCourse] = useState([]);


    useEffect(() => {
        fetchdata();
        fetchMentorDetails()
        setSelectedCourse([])
    }, [])


    const fetchdata = async () => {
        setLoading(true)
        const responsedata = await ConstructorEventGet(FetchAllCourses)
        console.log("thee courses are ", responsedata)
        setCourses(responsedata)
        setLoading(false)
    }


    const fetchMentorDetails = async () => {
        const responsedata = await ConstructorEventGet(FetchMentorDetails)
        setMentors(responsedata)
    }

    const selectMentor = (value) => {
        setSelectedMentor(value)
        const sk = (mentors.filter((ele) => {
            return ele.user_id == value
        }))

        console.log("the selected metor course is", sk[0].course)
        setSelectedCourse(sk[0].course)

    }

    const selectCourse = (course_id, course_name, event) => {
        const { value, checked } = event.target;

        if (selectedCourse == null) {
            setSelectedCourse([{ 'course_id': `${course_id}`, "course_name": `${course_name}` }]);

        } else {
            if (checked) {
                // If checkbox is checked, add the value to the list
                setSelectedCourse([...selectedCourse, { 'course_id': `${course_id}`, "course_name": `${course_name}` }]);
            } else {
                // If checkbox is unchecked, remove the value from the list
                setSelectedCourse(selectedCourse.filter(item => item.course_id !== course_id));
            }
        }
    }



    const assignCourseToMentor = async () => {


        setLoading(true)
        radiosRef.current.forEach((radio) => {
            radio.checked = false;
        });
        checkboxesRef.current.forEach((checkbox) => {
            checkbox.checked = false;
        });

        let Params = {
            "UserId": selectedMentor,
            "Courses": selectedCourse
        }

        const responsedata = await ConstructorEventPost(AssignCourseToMentor, Params)
        if (responsedata.statusCode == 200) {
            notifySuccess("Course Assigned to mentor Successfully")
            setSelectedCourse([]);
            setSelectedMentor("");

            navigate(0)

            // window.location.reload()
        } else {
            notifyError("Error while assigning course to mentor")
        }

    }

    return (
        <>
            <Header />
            {loading ?
                <div className="spinner" >
                    <div className="spinner-wrapper">
                        <ClassicSpinner size={50} color="black" loading={loading} />
                    </div></div> :
                <div className="nav-tab-wrapper tabs  section-padding">
                    <div className="container">
                        <div className="mini-title">Assign Course To Mentor</div>
                        <div className=" grid grid-cols-12 gap-[30px]">
                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 ">
                                <div className="bg-white shadow-box7 p-8 rounded-md mt-8">
                                    <div className="text-center">
                                        <b>Courses</b>
                                    </div>
                                    <div className="hover:shadow-box hover:bg-red">
                                        {courses.map((ele, index) => (
                                            <p className="shadow-sm p-4 bg-body-tertiary rounded" style={{ cursor: "pointer" }} >
                                                <input
                                                type="checkbox"
                                                id={index}
                                                checked={selectedCourse.some((course) => course.course_id === ele.course_id)}
                                                value={ele}
                                                ref={(el) => (radiosRef.current[index] = el)}
                                                onChange={(event) => selectCourse(ele.course_id, ele.course_name, event)} />  {ele.course_name}</p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 mt-8">
                                <div className="bg-white shadow-box7 p-8 rounded-md">
                                    <div className="text-center">
                                        <b>Mentors</b>
                                    </div>
                                    <div>
                                        {mentors.map((ele, index) => (
                                            <p className="shadow-sm p-4 bg-body-tertiary rounded" style={{ cursor: "pointer" }} >
                                                <input type='radio'
                                                    name={selectedMentor}
                                                    id={index} value={ele.user_id}
                                                    ref={(el) => (checkboxesRef.current[index] = el)}
                                                    onChange={() => selectMentor(ele.user_id)} />
                                                {" "}{ele.user_name}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="text-center mt-6">
                            <button className="btn btn-primary" type="" onClick={assignCourseToMentor}>Assign Courses To The Mentor</button>
                        </div>

                    </div>
                </div>}

        </>

    )
}

export default AssiginingCourseToMentor;