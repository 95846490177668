import React, { useEffect, useState } from "react";
import Header from "../Header";
import { ClassicSpinner, CombSpinner } from "react-spinners-kit";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";
import { FetchMentorDetails } from "../../api/aws-api";
import { ConstructorEventGet } from "../../Event/event";
import { FetchBatchData } from "../../api/aws-api";
import { Tooltip } from 'react-tooltip';
import { useNavigate } from "react-router-dom";
import "../../assets/css/calenderactivity.css";
import Swal from 'sweetalert2'
import { gapi } from 'gapi-script';



const CLIENT_ID = '347764156937-klkgu25nrg2l7pq8iticmrvha29f3vr7.apps.googleusercontent.com';
const API_KEY = 'AIzaSyCio8YcxXtf28_wN_ZWIHIjrlmzjGbXTJg';
const SCOPES = 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/admin.reports.audit.readonly';


function BatchManagement() {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [mentorList, setMentorList] = useState([])
    const [mentorName, setMentorName] = useState("")
    const [calenderEvents, setCalenderEvents] = useState([])
    const [mentorId, setMentorId] = useState("")
    const [batch, setBatch] = useState([])
    const batchStatusList = ["Scheduled", "InProgress", "Cancelled", "Completed"]
    const [selectedBatches, setSelectedBatches] = useState([]);
    const [hideCalender, setHideCalender] = useState(false)
    const [status, setStatus] = useState("Cancelled")
    const [batchList, setBatchList] = useState([])
    const [signedIn, setSignedIn] = useState(false);
    const [meetLink, setMeetLink] = useState('');
    const [participants, setParticipants] = useState([]);
    const [eventDetails, setEventDetails] = useState([]);
    const [duration, setDuration] = useState();
    const [mentorExists, setMentorExists] = useState(false);
    const [studentExists, setStudentExists] = useState(false);
    const [meetStartTime, setMeetStartTime] = useState('');
    const [meeetEndTime, setMeetEndTime] = useState('');
    const [meetIdetifier, setMeetIdentifier] = useState();
    const [mentorsTime, setMentorsTime] = useState('');
    const [meetDiff, setMeetDiff] = useState();
    const [meetId, setMeetId] = useState('');
    const [sTime, setSTime] = useState();
    const [mentorETime, setMentorETime] = useState();
    const [mentoremail, setMentoremail] = useState("");
    const [colorChangeInCalender, setColorChangeInCalender] = useState("")
    const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);



    useEffect(() => {
        // Initial setup
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);

        getMentorsList();
        setSelectedBatches([]);
        setBatchList([]);
        setMentorId("");

        // Google API setup
        function start() {
            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                scope: SCOPES,
            }).then(() => {
                gapi.client.load('calendar', 'v3'); // Load the Calendar API library
                const authInstance = gapi.auth2.getAuthInstance();
                authInstance.signIn();
                setSignedIn(authInstance.isSignedIn.get());
                authInstance.isSignedIn.listen(setSignedIn);
            });
        }

        gapi.load('client:auth2', start);


    }, []);


    useEffect(() => {
        console.log(isAnimationPlaying, "isAnimationPlaying after update");
        if (isAnimationPlaying) {
            // Animation is playing, you can trigger any additional behavior here
            console.log("Animation started playing");
        }
    }, [isAnimationPlaying]);


    const fetchActivities = (targetMeetingCode, startingTime, event) => {


        // Start animation
        setIsAnimationPlaying(true);

        if (!gapi.client || !gapi.client.request) {
            console.error('Google API client is not loaded or initialized');
            setLoading(false);
            return;
        }

        gapi.client.request({
            path: 'https://admin.googleapis.com/admin/reports/v1/activity/users/all/applications/meet',
            method: 'GET',
        }).then(response => {
            const eventsData = response.result.items.flatMap(item => item.events.map(event => {

                response.result.items.forEach((item) => {
                    const time = item.id.time;
                    const events = item.events;
                });



                if (!item.id || !item.events) return null;






                const identifierParam = event.parameters ? event.parameters.find(param => param.name === 'identifier') : null;
                const meetingCodeParam = event.parameters ? event.parameters.find(param => param.name === 'meeting_code') : null;
                const durationParam = event.parameters ? event.parameters.find(param => param.name === 'duration_seconds') : null;


                return {
                    time: item.id.time,
                    identifier: identifierParam ? identifierParam.value : 'na',
                    meeting_code: meetingCodeParam ? meetingCodeParam.value : 'na',
                    duration_seconds: durationParam ? durationParam.intValue : 'na',
                };
            }));

            console.log(eventsData, "eventsdata")

            const filteredEvents = eventsData.filter(event => event.meeting_code === targetMeetingCode);
            console.log("filteredEvents", filteredEvents)

            const dateString = startingTime;
            console.log(dateString, "stating time")

            const dateObject = new Date(dateString);

            // Extract the year, month, and day
            const years = dateObject.getFullYear();
            const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(dateObject.getDate()).padStart(2, '0');

            // Format the date as YYYY-MM-DD
            const formattedDate = `${years}-${month}-${day}`;


            console.log("the formatted date is", formattedDate)

            const filterEventByDate = filteredEvents.filter(event => event.time.split("T")[0] == formattedDate)


            const hours = dateObject.getHours();
            const minutes = dateObject.getMinutes();
            const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
            console.log("The extracted time is", formattedTime);

            const startTime = new Date(dateString);

            // Ending time is 1 hour after the start time
            const endTime = new Date(startTime.getTime() + 1 * 60 * 60 * 1000); // Adds 1 hour (in milliseconds)


            // Function to check if a meeting time is within the start and end time range
            const isWithinTimeRange = (meetingTime) => {
                const time = new Date(meetingTime);
                console.log(time, "time")
                console.log(startTime, "startTime")
                console.log(endTime, "endTime")

                return time >= startTime && time <= endTime;
            };

            const filteredMeetings = filterEventByDate

            console.log("yyyyyyyyyyyyyyyyyyyy", filteredMeetings)

            const maxDuration = Math.max(
                ...filteredMeetings
                    .filter(meeting => meeting.duration_seconds !== 'na') // Exclude 'na' values
                    .map(meeting => parseInt(meeting.duration_seconds, 10))
            );

            console.log("Max Duration (seconds)", maxDuration);

            const maxDurationInMinutes = (maxDuration / 60).toFixed(2);

            console.log("max duration in min", maxDurationInMinutes)

            // Find earliest time from filteredMeetings
            const earliestTime = new Date(
                Math.min(
                    ...filteredMeetings
                        .map(meeting => new Date(meeting.time))
                )
            );

            console.log(earliestTime, "earliestTime");

            const earliestTimeFormatted = earliestTime.toLocaleString();

            console.log("Earliest Time", earliestTimeFormatted);

            const earliestMeeting = filteredMeetings.find(
                meeting => new Date(meeting.time).getTime() === earliestTime.getTime()
            );


            console.log("Corresponding Duration Seconds:to earliest time", earliestMeeting.duration_seconds);

            const durationInMilliseconds = earliestMeeting.duration_seconds * 1000;
            const adjustedTime = new Date(earliestTime.getTime() - durationInMilliseconds);

            // Format the adjusted time to display
            const adjustedTimeFormatted = adjustedTime.toLocaleString();
            console.log("Adjusted Time", adjustedTimeFormatted);


            const largestTime = new Date(
                Math.max(
                    ...filteredMeetings
                        .map(meeting => new Date(meeting.time))
                )
            );

            const largestTimeFormatted = largestTime.toLocaleString();

            console.log("Largest Time", largestTimeFormatted);

            const timeDifferenceInMilliseconds = largestTime.getTime() - adjustedTime.getTime();

            // Convert milliseconds to minutes (1 minute = 60,000 milliseconds)
            const timeDifferencefordurationInMinutes = timeDifferenceInMilliseconds / (1000 * 60);

            console.log("Time Difference in Minutes for duration :", timeDifferencefordurationInMinutes);

            const timeDifferenceWholeMinutes = Math.floor(timeDifferencefordurationInMinutes);
            console.log("Time Difference in Minutes (whole number):", timeDifferenceWholeMinutes);

            const identifiers = Array.from(new Set(filteredMeetings.map(meeting => meeting.identifier)))
                .filter(identifier => identifier !== 'na') // Exclude 'na' values
                .join(', ');

            console.log("Identifiers:", identifiers);

            const mentorDurations = filteredMeetings
                .filter(meeting => meeting.identifier === mentorName) // Filter by mentor's email
                .map(meeting => meeting.duration_seconds) // Extract duration_seconds
                .filter(duration => duration !== 'na'); // Remove 'na' values

            console.log("Mentor's Duration Seconds:", mentorDurations);

            // If you need the maximum duration among mentor's durations
            const maxMentorDuration = Math.max(...mentorDurations.map(duration => parseInt(duration, 10)));
            console.log("Max Mentor Duration (seconds):", maxMentorDuration);

            console.log("mentor name", mentorName)

            const mentorMeetings = filteredMeetings.filter(meeting => meeting.identifier === mentoremail);

            console.log(mentorMeetings, "mentorMeeting")

            // Find the meeting with the lowest duration_seconds
            const meetingWithLowestDuration = mentorMeetings
                .filter(meeting => meeting.duration_seconds !== 'na') // Exclude 'na' values
                .reduce((min, meeting) => {
                    const duration = parseInt(meeting.duration_seconds, 10);
                    return (duration < min.duration) ? { meeting, duration } : min;
                }, { meeting: null, duration: Infinity });



            // Get the time of the meeting with the lowest duration
            const lowestDurationMeetingTime = meetingWithLowestDuration.meeting ? new Date(meetingWithLowestDuration.meeting.time) : null;

            // Format the time in local format
            const lowestDurationMeetingTimeFormatted = lowestDurationMeetingTime ? lowestDurationMeetingTime.toLocaleString() : 'No valid meetings found';

            console.log("Time of Meeting with Lowest Duration (in local format):", lowestDurationMeetingTimeFormatted);


            // Convert duration_seconds to milliseconds
            const durationInMillisecondsforMentor = meetingWithLowestDuration.duration * 1000;

            // Subtract duration from meeting time
            const adjustedTimeforMentor = new Date(lowestDurationMeetingTime.getTime() - durationInMillisecondsforMentor);

            // Format the adjusted time to display in a human-readable format
            const adjustedTimeFormattedforMenetor = adjustedTimeforMentor.toLocaleString();
            console.log("Adjusted Time for mentor", adjustedTimeFormattedforMenetor);



            const mentorEvents = filteredEvents.filter(event => event.identifier === mentorId || event.identifier === mentorName);
            console.log(event, "event")
            console.log(mentorId, "mentorID")
            console.log("mentorEvents", mentorEvents)

            const scheduledDate = `${startingTime.toLocaleDateString()} ${startingTime.toLocaleTimeString()}`

            const scheduledDateObj = new Date(scheduledDate);
            // lowestDurationMeetingTimeFormatted is the formatted string from your code
            const lowestDurationMeetingTimeObj = new Date(adjustedTimeFormattedforMenetor);


            // Extract the hours and minutes from the scheduled date
            const scheduledHours = scheduledDateObj.getHours();
            const scheduledMinutes = scheduledDateObj.getMinutes();

            // Extract the hours and minutes from the lowest duration meeting time
            const lowestHours = lowestDurationMeetingTimeObj.getHours();
            const lowestMinutes = lowestDurationMeetingTimeObj.getMinutes();

            // Convert both times into total minutes
            const scheduledTotalMinutes = scheduledHours * 60 + scheduledMinutes;
            const lowestTotalMinutes = lowestHours * 60 + lowestMinutes;

            // Calculate the absolute difference in minutes
            const timeDifferenceInMinutes = Math.abs(scheduledTotalMinutes - lowestTotalMinutes);

            // Convert the difference into hours and minutes
            const differenceHours = Math.floor(timeDifferenceInMinutes / 60);
            const differenceMinutes = timeDifferenceInMinutes % 60;

            console.log(`Time difference: ${differenceHours} hours, ${differenceMinutes} minutes`);


            const timeArray = filteredEvents.map(item => new Date(item.time));

            // Find minimum and maximum times
            const min = new Date(Math.min(...timeArray));
            const max = new Date(Math.max(...timeArray));



            const meetStartTime = min.toLocaleString()
            const meetEndTime = max.toLocaleString()



            const identifierDurations = filteredEvents.reduce((acc, curr) => {
                const { identifier, duration_seconds } = curr;
                if (duration_seconds === 'na') return acc;

                const duration = parseInt(duration_seconds);
                if (!acc[identifier]) {
                    acc[identifier] = duration;
                } else {
                    acc[identifier] = Math.max(acc[identifier], duration);
                }
                return acc;
            }, {});

            const meetingAttendees = Object.keys(identifierDurations).join(', ');

            const mentorearlyTime = mentorEvents.reduce((earliest, curr) => {
                const currTime = new Date(curr.time);
                return currTime < earliest ? currTime : earliest;
            }, new Date(filteredEvents[0].time));

            console.log(filteredEvents[0], "filter0")

            const mentorsMeetingSecond = filteredEvents[0].duration_seconds !== 'na'
                ? filteredEvents[0].duration_seconds / 60
                : 0;

            const mentorTime = new Date(mentorearlyTime.getTime() - mentorsMeetingSecond * 60000)

            const mentorJoiningTime = `${mentorTime.toLocaleDateString()} ${mentorTime.toLocaleTimeString()}`

            console.log("meettt", min, mentorTime)



            const meetingTime = max - min
            const meetInMinutes = meetingTime / (1000 * 60);
            const meetTotalTimingInMinutes = meetInMinutes.toFixed(2)


            const differenceInMilliseconds = mentorTime - min;
            // Convert milliseconds to minutes
            const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

            const mentorLateBy = differenceInMinutes.toFixed(2)


            Swal.fire({
                icon: "info",
                title: "The Batch details are",
                html: `
                <p>Scheduled Time: ${scheduledDate}</p>
                <p>Start Time: ${adjustedTimeFormatted}</p>
                <p>End Time: ${largestTimeFormatted}</p>  <!-- Include endTime here -->
                <p>Batch Details: ${event.title}</p>
                <p>Participants: ${identifiers}</p>
                <p>Mentor Start Time : ${adjustedTimeFormattedforMenetor}</p>
                <p>Duration (minutes): ${timeDifferenceWholeMinutes}</p>
                <p>Mentor is late by : ${identifiers.includes(mentoremail) ? `${differenceMinutes}(minutes) from the scheduled time` : `Mentor didn't joined the meeting`}  </p>
                <p>Meeting Code: ${targetMeetingCode}</p>
            `
            });
            setIsAnimationPlaying(false);
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching activities:', error);
            setLoading(false);
            Swal.fire({
                icon: "info",
                title: "The Batch details are",
                html: `
                <p>Scheduled Time: ${startingTime.toLocaleString()}</p>
                <p>Start Time: meeting not started yet</p>
                <p>End Time: meeting not started yet</p>
                <p>Batch Details: ${event.title}</p>
                <p>Participants: meeting not started yet</p>
                <p>Meeting Code: ${targetMeetingCode}</p>
                                `

            });
            setIsAnimationPlaying(false);
        });




    };


    const getMentorsList = async () => {
        const responsedata = await ConstructorEventGet(FetchMentorDetails)
        setMentorList(responsedata)
    }


    const selectMentor = async (e) => {
        setSelectedBatches([])
        setBatch([])
        setCalenderEvents([])
        setMentorName(e.target.value)

        console.log("the mentor details", mentorList)
        console.log("the selected mentor is", e.target.value)


        const mentorDetails = mentorList.filter((ele) => {
            return ele.user_name === e.target.value
        })

        console.log("the mentor details", mentorDetails)

        const mentorid = mentorDetails[0].user_id
        setMentorId(mentorid)

        const mentorsmail = mentorDetails[0].user_email
        setMentoremail(mentorsmail)

        const responsedata = await ConstructorEventGet(FetchBatchData, { "mentorId": mentorid })
        console.log(responsedata, "batchdata");
        setBatchList(responsedata)


        batchList.forEach((batch, index) => {
            console.log(`Class Schedule for Batches ${index + 1} (${batch.BatchName}):`, batch.ClassSchedule);
        });


    }




    const handleCheckboxChange = (event) => {

        const value = event.target.value;
        setSelectedBatches((prevSelectedItems) =>
            prevSelectedItems.includes(value)
                ? prevSelectedItems.filter((item) => item !== value)
                : [...prevSelectedItems, value]
        );

    }

    useEffect(() => {
        displayEventsInTheCalender()

    }, [selectedBatches])




    const displayEventsInTheCalender = async () => {
        // Filter events based on batch progress



        const scheduledEventsList = batchList.filter((ele) => ele.batchProgress === "Scheduled");
        const inProgressEventsList = batchList.filter((ele) => ele.batchProgress === "InProgress");
        const completedEventsList = batchList.filter((ele) => ele.batchProgress === "Completed")
        const cancelledEventsList = batchList.filter((ele) => ele.batchProgress === "Cancelled")


        console.log("The calender events are calender events and the batch list is", calenderEvents, batchList)

        // Step 1: Group calendar events by `meeting_code` in `extendedProps`
        const groupedByMeetingCode = calenderEvents.reduce((acc, event) => {
            const meetingCode = event.extendedProps.meeting_code;

            if (!acc[meetingCode]) {
                acc[meetingCode] = []; // Initialize an array for this meeting code if not already present
            }

            acc[meetingCode].push(event); // Add the event under its corresponding meeting code
            return acc;
        }, {});

        // Step 2: For each meeting code, group by date extracted from the `start` field
        const groupedByMeetingCodeAndDate = Object.entries(groupedByMeetingCode).reduce((acc, [meetingCode, events]) => {
            acc[meetingCode] = events.reduce((dateAcc, event) => {
                const eventDate = event.start.split('T')[0]; // Extract the date part from 'start'

                if (!dateAcc[eventDate]) {
                    dateAcc[eventDate] = []; // Initialize an array for this date if not already present
                }

                dateAcc[eventDate].push(event); // Add the event under its corresponding date
                return dateAcc;
            }, {});

            return acc;
        }, {});

        // Step 3: For each meeting code and date, group by time extracted from the `start` field
        const groupedByMeetingCodeDateAndTime = Object.entries(groupedByMeetingCodeAndDate).reduce((acc, [meetingCode, eventsByDate]) => {
            acc[meetingCode] = Object.entries(eventsByDate).reduce((dateAcc, [eventDate, events]) => {
                dateAcc[eventDate] = events.reduce((timeAcc, event) => {
                    const eventTime = event.start.split('T')[1]; // Extract the time part (HH:MM:SS) from 'start'

                    if (!timeAcc[eventTime]) {
                        timeAcc[eventTime] = []; // Initialize an array for this time if not already present
                    }

                    timeAcc[eventTime].push(event); // Add the event under its corresponding time
                    return timeAcc;
                }, {});

                return dateAcc;
            }, {});

            return acc;
        }, {});

        // Step 4: Log the grouped events by meeting code, date, and time
        for (const [meetingCode, eventsByDate] of Object.entries(groupedByMeetingCodeDateAndTime)) {
            // console.log(`Meeting Code: ${meetingCode}`);

            for (const [date, eventsByTime] of Object.entries(eventsByDate)) {
                //console.log(`  Events for date ${date}:`);

                for (const [time, events] of Object.entries(eventsByTime)) {
                    //console.log(`    Events for time ${time}:`, events);
                }
            }
        }



        function colorChangeDuration(elee) {
            setColorChangeInCalender("")
            const meetlink = elee.meet_link
            const meetid = meetlink.split("/").pop()
            const meetingid = meetid.split("-")
            const meetidStingcap = meetingid.join('').toUpperCase();

            if (!gapi.client || !gapi.client.request) {
                console.error('Google API client is not loaded or initialized');
                setLoading(false);
                return;
            }

            gapi.client.request({
                path: 'https://admin.googleapis.com/admin/reports/v1/activity/users/all/applications/meet',
                method: 'GET',
            }).then(response => {
                const eventsData = response.result.items.flatMap(item => item.events.map(event => {

                    response.result.items.forEach((item) => {
                        const time = item.id.time;
                        const events = item.events;
                    });




                    const identifierParam = event.parameters.find(param => param.name === 'identifier');
                    const meetingCodeParam = event.parameters.find(param => param.name === 'meeting_code');
                    const durationParam = event.parameters.find(param => param.name === 'duration_seconds');

                    return {
                        time: item.id.time,
                        identifier: identifierParam ? identifierParam.value : 'na',
                        meeting_code: meetingCodeParam ? meetingCodeParam.value : 'na',
                        duration_seconds: durationParam ? durationParam.intValue : 'na',
                    };
                }));

                const filteredEvents = eventsData.filter(event => event.meeting_code === meetidStingcap);



                const mentorEvents = filteredEvents.filter(event => event.identifier === mentorId || event.identifier === mentorName);

                console.log("filteredEvents", filteredEvents)

                const timeArray = filteredEvents.map(item => new Date(item.time));

                const min = new Date(Math.min(...timeArray));
                const max = new Date(Math.max(...timeArray));


                const meetingTime = max - min
                const meetInSeconds = meetingTime.toString().slice(0, 4);



                setColorChangeInCalender(meetInSeconds)

            }).catch(error => {
                console.error('Error fetching activities:', error);
                setLoading(false);
            });

        }



        // Create events with extended properties
        const scheduledEvents = scheduledEventsList.flatMap((ele) =>
            ele.ClassSchedule.map((elee) => (

                colorChangeDuration(elee),

                {


                    title: `${ele.BatchName.split(" ")[0]}(${ele.batchProgress})(${ele.StudentsEndrolled.map((stu) => stu.name).join(", ")})`,
                    start: elee.startDateTime,
                    extendedProps: {
                        identifier: 'na', // Placeholder; should be updated with actual data
                        duration_seconds: 'na', // Placeholder; should be updated with actual data
                        meeting_code: `${elee.meet_link}`,
                        duration: `${colorChangeInCalender}`,// Placeholder; should be updated with actual data
                    }
                }))
        );

        const inProgressEvents = inProgressEventsList.flatMap((ele) =>
            ele.ClassSchedule.map((elee) => (

                colorChangeDuration(elee), {
                    title: `${ele.BatchName.split(" ")[0]}(${ele.batchProgress})(${ele.StudentsEndrolled.map((stu) => stu.name).join(", ")})`,
                    start: elee.startDateTime,
                    extendedProps: {
                        identifier: 'na', // Placeholder; should be updated with actual data
                        duration_seconds: 'na', // Placeholder; should be updated with actual data
                        meeting_code: `${elee.meet_link}`,
                        duration: `${colorChangeInCalender}`, // Placeholder; should be updated with actual data
                    }
                }))
        );

        const completedEvents = completedEventsList.flatMap((ele) =>
            ele.ClassSchedule.map((elee) => (
                colorChangeDuration(elee),
                {
                    title: `${ele.BatchName.split(" ")[0]}(${ele.batchProgress})(${ele.StudentsEndrolled.map((stu) => stu.name).join(", ")})`,
                    start: elee.startDateTime,
                    extendedProps: {
                        identifier: 'na', // Placeholder; should be updated with actual data
                        duration_seconds: 'na', // Placeholder; should be updated with actual data
                        meeting_code: `${elee.meet_link}`,
                        duration: `${colorChangeInCalender}`, // Placeholder; should be updated with actual data
                    }
                }))
        );

        const cancelledEvents = cancelledEventsList.flatMap((ele) =>
            ele.ClassSchedule.map((elee) => (
                colorChangeDuration(elee),
                {
                    title: `${ele.BatchName.split(" ")[0]}(${ele.batchProgress})(${ele.StudentsEndrolled.map((stu) => stu.name).join(", ")})`,
                    start: elee.startDateTime,
                    extendedProps: {
                        identifier: 'na', // Placeholder; should be updated with actual data
                        duration_seconds: 'na', // Placeholder; should be updated with actual data
                        meeting_code: `${elee.meet_link}`,
                        duration: `${colorChangeInCalender}`,// Placeholder; should be updated with actual data
                    }
                }))
        );

        // Handle batch selection and update calendar events
        if (selectedBatches.length === 1) {
            handleSingleBatch(selectedBatches[0]);
        } else if (selectedBatches.length === 2) {
            handleTwoBatches(selectedBatches[0], selectedBatches[1]);
        } else if (selectedBatches.length > 2) {
            alert("You can't select more than two batch statuses");
            setSelectedBatches([]);
        } else {
            setCalenderEvents([]);
            setHideCalender(false);
        }

        function handleSingleBatch(value1) {
            if (value1 === "Scheduled") {
                const eventsToDisplayInCalender = [...scheduledEvents]
                setCalenderEvents(eventsToDisplayInCalender)
                const result = batchList.filter((ele) => {
                    return ele.batchProgress === "Scheduled"
                })

                if (result.length === 0) {
                    Swal.fire({
                        icon: "info",
                        title: `There are no Scheduled batches.`,
                    })
                    setSelectedBatches([])
                } else {
                    setHideCalender(true)
                    setBatch(result)
                }

            } else if (value1 === "InProgress") {
                const eventsToDisplayInCalender = [...inProgressEvents]
                setCalenderEvents(eventsToDisplayInCalender)
                const result = batchList.filter((ele) => {
                    return ele.batchProgress === "InProgress"
                })

                if (result.length === 0) {
                    Swal.fire({
                        icon: "info",
                        title: `There are no InProgress batches.`,
                    })
                    setSelectedBatches([])
                } else {
                    setHideCalender(true)
                    setBatch(result)
                }
            } else if (value1 === "Cancelled") {
                setHideCalender(true)
                // const eventsToDisplayInCalender = [...cancelledEvents]
                // setCalenderEvents(eventsToDisplayInCalender)

                const result = batchList.filter((ele) => {
                    return ele.batchProgress === "Cancelled"
                })

                if (result.length === 0) {
                    Swal.fire({
                        icon: "info",
                        title: `There are no cancelled batches.`,
                    })
                    setSelectedBatches([])
                } else {
                    setBatch(result)
                }


            } else if (value1 === "Completed") {
                setHideCalender(true)
                // const eventsToDisplayInCalender = [...completedEvents]
                // setCalenderEvents(eventsToDisplayInCalender)
                const result = batchList.filter((ele) => {
                    return ele.batchProgress === "Completed"
                })

                if (result.length === 0) {
                    Swal.fire({
                        icon: "info",
                        title: `There are no completed batches.`,
                    })
                    setSelectedBatches([])
                } else {
                    setBatch(result)
                }

            }

        }




        function containsTwoValue(value1, value2) {
            if (value1 === "Scheduled" && value2 === "InProgress" || value1 === "InProgress" && value2 === "Scheduled") {
                const eventsToDisplayInCalender = [...scheduledEvents, ...inProgressEvents]
                setCalenderEvents(eventsToDisplayInCalender)
                setHideCalender(true)


                const value1 = batchList.filter((ele) => {
                    return ele.batchProgress === "Scheduled"
                })

                const value2 = batchList.filter((ele) => {
                    return ele.batchProgress === "InProgress"
                })


                setBatch([...value1, ...value2])

            } else if (value1 === "Cancelled" && value2 === "Completed" || value1 === "Completed" && value2 === "Cancelled") {
                setHideCalender(true)
                const value1 = batchList.filter((ele) => {
                    return ele.batchProgress === "Cancelled"
                })

                const value2 = batchList.filter((ele) => {
                    return ele.batchProgress === "Completed"
                })


                setBatch([...value1, ...value2])
            }
            else {
                alert(`You can't select ${value1} with ${value2}`)
                setSelectedBatches([])
            }
        }

        function handleTwoBatches(value1, value2) {
            if ((value1 === "Scheduled" && value2 === "InProgress") || (value1 === "InProgress" && value2 === "Scheduled")) {
                setCalenderEvents([...scheduledEvents, ...inProgressEvents]);
            } else if ((value1 === "Cancelled" && value2 === "Completed") || (value1 === "Completed" && value2 === "Cancelled")) {
                setHideCalender(true);
                // setCalenderEvents([...cancelledEvents, ...completedEvents]);
                const cancelledBatches = batchList.filter((ele) => ele.batchProgress === "Cancelled");
                const completedBatches = batchList.filter((ele) => ele.batchProgress === "Completed");
                setBatch([...cancelledBatches, ...completedBatches]);
            } else {
                alert(`You can't select ${value1} with ${value2}`);
                setSelectedBatches([]);
            }
        }
    }

    function goToAssignBatchToMentorPage() {
        navigate(mentorId ? "/codomo/admin/assignBatch" : "", mentorId ? { state: { mentorId, meetLink } } : "");
    }


    const handleEventClick = (info) => {

        console.log("Setting isAnimationPlaying to true BEFORE call");
        setIsAnimationPlaying(true);
        console.log("Setting isAnimationPlaying to true AFTER call");

        console.log("the calender events are", calenderEvents)




        const { event } = info;
        const stime = new Date(info.event.start)
        const meetlink = info.event._def.extendedProps.meeting_code
        const meetid = meetlink.split("/").pop()
        const meetingid = meetid.split("-")
        const meetidString = meetingid.join('');
        const meetidStingcap = meetingid.join('').toUpperCase(); // Joins without dashes and converts to uppercase: 'MIEQYBQDZE'
        setMeetId(meetidStingcap)
        console.log(stime, "startTime")
        fetchActivities(meetidStingcap, stime, event);


    };

    console.log("the calender events are", calenderEvents)


    return (
        <div>
            <Header />
            {loading ? (
                <div className="spinner">
                    <div className="spinner-wrapper">
                        <ClassicSpinner size={50} color="black" loading={loading} />
                    </div>
                </div>
            ) : (
                <div className="nav-tab-wrapper tabs section-padding">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p className="p-6">
                            Mentor Name:
                            <select onChange={selectMentor}>
                                <option value="">Select a mentor</option>
                                {mentorList?.map((ele) => (
                                    <option key={ele.id}>{ele.user_name}</option>
                                ))}
                            </select>
                        </p>
                        {mentorId && (
                            <div style={{ display: "flex" }}>
                                <p className="pt-6 pl-6">Batch Status:</p>
                                <div className="pt-6 pl-4" style={{ display: "flex" }}>
                                    {batchStatusList?.map((item) => (
                                        <div key={item}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    value={item}
                                                    checked={selectedBatches.includes(item)}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <b>{item}</b>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="pt-4 pl-6 pr-4 space-x-[18px]">
                            <button
                                className="btn btn-primary py-[15px] px-8"
                                onClick={goToAssignBatchToMentorPage}
                            >
                                Assign Batch To Mentor
                            </button>
                        </div>
                    </div>
                    {hideCalender ?
                        <div>
                            {!selectedBatches.includes("Scheduled") && !selectedBatches.includes("InProgress") && (
                                <div className="grid lg:grid-cols-4 md:grid-cols-2  grid-cols-1 gap-[30px] p-6">
                                    {batch.map((item, index) => (

                                        <div
                                            className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center hover:-translate-y-2 pt-4"
                                            key={index}
                                        >


                                            <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8] ">
                                                <iconify-icon icon={`${item.batchProgress === 'Completed' ? 'fluent-mdl2:completed-solid' : item.batchProgress === 'InProgress' ? 'grommet-icons:in-progress' : item.batchProgress === 'Scheduled' ? 'mdi:clipboard-text-date' : 'mdi:clipboard-text-date'}`} style={{ fontSize: '2em' }} />

                                            </div>
                                            <div className="course-content pb-6">

                                                <b>Batch Status : {item.batchProgress}</b>
                                                <br></br>
                                                <b className="font-bold">Course Name: {item.courseName}</b>
                                                <br></br>
                                                <b>BatchStudents: {item.StudentsEndrolled.map((ele) => (
                                                    <li>{ele.name}</li>
                                                ))}
                                                </b>
                                                <b>Batch Id: {item.BatchName.split("-").pop()}</b>
                                                <br></br>
                                                <b>Date: {item.StartDate.replaceAll("-", "/") + "  -  " + item.EndDate.replaceAll("-", "/")}</b>
                                                <br></br>
                                                <b>Timing: {item.StartTime + "  -  " + item.EndTime}</b>
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                            )}

                            <div className="container">
                                {calenderEvents && (
                                    <FullCalendar
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                        initialView="timeGridWeek"
                                        headerToolbar={{
                                            center: "dayGridMonth,timeGridWeek",
                                        }}
                                        weekends={true}
                                        selectable={true}
                                        dayMaxEvents={0}
                                        //eventBackgroundColor={}
                                        events={calenderEvents.map(event => {

                                            console.log("tttt", event.extendedProps.duration)
                                            let backgroundColor;

                                            return {
                                                ...event,
                                                backgroundColor: backgroundColor,
                                                textColor: "black"
                                            };
                                        })}
                                        eventClick={(eventInfo) => {
                                            // Custom event handler
                                            eventInfo.jsEvent.preventDefault(); // Prevent the default behavior
                                            handleEventClick(eventInfo); // Your custom logic when an event is clicked

                                            // Close the popover manually by simulating a click on the close button
                                            const popoverCloseButton = document.querySelector(".fc-popover-close");
                                            if (popoverCloseButton) {
                                                popoverCloseButton.click(); // Simulate a click on the close button
                                            }
                                        }}
                                    />
                                )}
                            </div>


                        </div> : <div className="xl:col-span-7 lg:col-span-6 col-span-12">
                            <div className="container">
                                {calenderEvents && (
                                    <FullCalendar
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                        initialView="timeGridWeek"
                                        headerToolbar={{
                                            center: "dayGridMonth,timeGridWeek",
                                        }}
                                        weekends={true}

                                        selectable={true}
                                        dayMaxEvents={0}

                                        //eventBackgroundColor={}
                                        events={calenderEvents.map(event => {

                                            // console.log("duration", duration)

                                            let backgroundColor;
                                            return {
                                                ...event,
                                                backgroundColor: backgroundColor,
                                                textColor: "black"
                                            };
                                        })}
                                        eventClick={(eventInfo) => {
                                            // Custom event handler
                                            eventInfo.jsEvent.preventDefault(); // Prevent the default behavior
                                            handleEventClick(eventInfo); // Your custom logic when an event is clicked

                                            // Close the popover manually by simulating a click on the close button
                                            const popoverCloseButton = document.querySelector(".fc-popover-close");
                                            if (popoverCloseButton) {
                                                popoverCloseButton.click(); // Simulate a click on the close button
                                            }
                                        }}
                                    />
                                )}
                            </div>


                        </div>
                    }

                    {isAnimationPlaying && (
                        <div className="spinner">
                            <ClassicSpinner size={50} color="black" loading={true} />
                        </div>
                    )}

                </div>
            )}

        </div>
    );
}

export default BatchManagement;